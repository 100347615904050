<template>
  <Navigation />
  <router-view />
  <Footer />
</template>

<script>
import Navigation from "./components/Nav.vue";
import Footer from "./components/Footer.vue";
import { computed, reactive } from "vue";
import { useHead } from "@vueuse/head";

export default {
  name: "App",
  components: {
    Navigation,
    Footer,
  },
  setup() {
    const siteData = reactive({
      title: "JS Trucking and Excavation",
      Description: "Portfolio Website",
    });

    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description),
        },
      ],
    });
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,700;1,800;1,900&display=swap");
html,
body {
  max-width: 100%;
  overflow-x: hidden;
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}

h1 {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 3.75rem;
  line-height: 4.375rem;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #000000;
}

h3 {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #000000;
}

h4 {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;

  color: #ffffff;
}

p {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  text-align: center;
  letter-spacing: 0.04em;
  font-size: 1.25rem;
  color: #ffffff;
}

@media (max-width: 500px) {
  h1 {
    font-size: 2.5rem;
    line-height: 2.91rem;
    letter-spacing: 0.053em;
  }
}
</style>
