<template>
  <h1 style="padding-top: 2rem"><a id="projectsPage">Projects</a></h1>
  <el-carousel height="70vh" indicator-position="none" interval="3200">
    <el-carousel-item v-for="src in images" :key="src">
      <img class="pImage" :src="src" />
    </el-carousel-item>
  </el-carousel>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      images: [
        "https://live.staticflickr.com/65535/51823050920_10c0236538_b.jpg",
        "https://live.staticflickr.com/65535/51822331266_871b9a5f6c_b.jpg",
        "https://live.staticflickr.com/65535/51822675849_3408c1505e_b.jpg",
        "https://live.staticflickr.com/65535/51821384767_fdd6057b26_b.jpg",
        "https://live.staticflickr.com/65535/51822443168_3de66d207f_b.jpg",
        "https://live.staticflickr.com/65535/51821384957_1fcac4253a_b.jpg",
        "https://live.staticflickr.com/65535/51822443558_3c1474c22c_b.jpg",
        "https://live.staticflickr.com/65535/51821385112_3805e24470_b.jpg",
        "https://live.staticflickr.com/65535/51822330816_95233ede54_b.jpg",
        "https://live.staticflickr.com/65535/51822676879_a8ea044f1e_b.jpg",
        "https://live.staticflickr.com/65535/51821385547_754e9913d9_b.jpg",
        "https://live.staticflickr.com/65535/51822332991_6a9804fb21_b.jpg",
        "https://live.staticflickr.com/65535/51821386292_c5162a94a1_b.jpg",
        "https://live.staticflickr.com/65535/51822678689_0ef4c4f31a_b.jpg",
        "https://live.staticflickr.com/65535/51822333961_b98ce0316f_b.jpg",
        "https://live.staticflickr.com/65535/51823050745_f0d65b387e_b.jpg",
        "https://live.staticflickr.com/65535/51855044139_00ffa0aa1a_o.jpg",
        "https://live.staticflickr.com/65535/51854718696_27bff288c0_b.jpg",
        "https://live.staticflickr.com/65535/51854798938_dcf18b6449_b.jpg",
        "https://live.staticflickr.com/65535/51854718601_dc72cc6c81_b.jpg",
        "https://live.staticflickr.com/65535/51855375245_ca82ea1461_b.jpg",
        "https://live.staticflickr.com/65535/51855044079_6d31093193_o.jpg",
        "https://live.staticflickr.com/65535/52166167915_b796f42dff_h.jpg",
        "https://live.staticflickr.com/65535/52166167735_1908893e86_h.jpg",
        "https://live.staticflickr.com/65535/52165694823_6e6791af27_h.jpg",
        "https://live.staticflickr.com/65535/52165926834_1836b4762c_h.jpg",
        "https://live.staticflickr.com/65535/52165693043_6f9129f61b_h.jpg",
        "https://live.staticflickr.com/65535/52166173070_02af4bf6f4_h.jpg",
        "https://live.staticflickr.com/65535/52165924079_059335929d_h.jpg",
        "https://live.staticflickr.com/65535/52166167595_6a1d08d6f6_c.jpg",
        "https://live.staticflickr.com/65535/52164660057_23eced9f2f_c.jpg",
        "https://live.staticflickr.com/65535/52165919799_32c420a240_c.jpg",
        "https://live.staticflickr.com/65535/52164658657_35e6094037_c.jpg",
      ],
    };
  },
  methods: {
    show() {
      this.$viewerApi({
        images: this.images,
      });
    },
  },
});
</script>

<style scoped>
.projectWrapper {
  width: 90vw;
  max-width: 100rem;
}
.projectImage {
  padding: 0.5rem;
  object-fit: cover;
  width: 18rem;
  height: 18rem;
}

.el-carousel__item {
  background-color: white;
}

.el-carousel {
  width: 100vw;
  max-width: 55rem;
}

.pImage {
  height: 100%;
  width: auto;
  object-fit: cover;
}

@media (max-width: 500px) {
}
</style>
