<template>
  <div class="serviceWrapper">
    <h1 class="serviceTitle"><a id="servicePage">Services</a></h1>
    <h3 class="credit">
      THANK YOU Arafat Uddin ON thenounproject.com FOR THE CREATION OF BULDOZER
      SVG. JS TRUCKING AND EXCAVATION DOES NOT TAKE ANY CREDIT FOR THE DESIGN OF
      THIS ICON.
    </h3>
    <h3 class="credit">
      THANK YOU Juicy Fish ON thenounproject.com FOR THE CREATION OF EXCAVATOR
      SVG. JS TRUCKING AND EXCAVATION DOES NOT TAKE ANY CREDIT FOR THE DESIGN OF
      THIS ICON.
    </h3>
    <h3 class="credit">
      THANK YOU thenounproject.com FOR THE DUMPTRUCK SVG. JS TRUCKING AND
      EXCAVATION DOES NOT TAKE ANY CREDIT FOR THE DESIGN OF THIS ICON.
    </h3>
    <div class="iconWrapper">
      <div class="service">
        <animated-component class="iconBox">
          <img
            class="icon"
            src="../assets/dumptruck.svg"
            alt="Dump Truck Icon From thenounproject.com"
          />
        </animated-component>
        <h3 class="serviceTitle">dump truck &amp; hauling</h3>
      </div>
      <div class="service trailer">
        <animated-component class="iconBox">
          <img
            class="icon"
            src="../assets/bulldozer.svg"
            alt="Bulldozer Icon from Arafat Uddin"
          />
        </animated-component>
        <h3 class="serviceTitle">Grading &amp; Road Building</h3>
      </div>
      <div class="service ex">
        <animated-component class="iconBox">
          <img
            class="icon"
            src="../assets/excavator.svg"
            alt="Excavator Icon from Juicy Fish"
          />
        </animated-component>
        <h3 class="serviceTitle">Excavation <br />services</h3>
      </div>
    </div>
    <div class="estimateButton">
      <h4><a href="#contactPage">get an estimate</a></h4>
    </div>
  </div>
</template>
<script>
import AnimatedComponent from "@/components/AnimatedComponent.vue";

export default {
  name: "Service",
  components: {
    AnimatedComponent,
  },
};
</script>

<style scoped>
.credit {
  font-size: 0.5rem;
  display: none;
}
.iconBox {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 20vw;
  max-width: 16rem;
  height: 25vw;
  max-height: 20rem;
}

#servicePage {
  padding-top: 1rem;
}
.serviceWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 5rem;
}
.iconWrapper {
  width: 80vw;
  max-width: 70rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: -2rem 0 2rem 0;
}
.service {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.icon {
  width: 20vw;
  max-width: 16rem;
}
.serviceTitle {
  max-width: 16rem;
  width: 20vw;
  min-width: 10rem;
  display: flex;
  justify-content: center;
}

.estimateButton {
  height: 2.5rem;
  background: linear-gradient(114.7deg, #000000 10.42%, #2e2e2e 90.09%);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14rem;
}
.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1.2s ease-out;
  will-change: opacity, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

@media (max-width: 750px) {
  .iconWrapper {
    flex-direction: column;
    align-items: center;
  }
  .icon {
    width: 50vw;
  }

  .trailer {
    margin: -2rem 0 3rem 0;
  }
  .iconBox {
    width: 50vw;
    height: 50vw;
  }
}
</style>
