<template>
  <div class="wrapper">
    <animated-component animation-type="zoom">
      <div class="animationWrapper">
        <h1>SUCCESS</h1>
        <img class="icon" src="../assets/dumptruck.svg" alt="Dump Truck Icon" />
        <p>
          Your Message was successfully sent to JS Trucking and Excavation. We
          will get back to you shortly via email or call. Thank you for your
          pataience.
        </p>
        <div class="homeButton">
          <h4><a href="/">Back to Home Page</a></h4>
        </div>
      </div>
    </animated-component>
  </div>
</template>
<script lang="js">
import AnimatedComponent from '@/components/AnimatedComponent.vue';
export default {
  name: "App",
  components: {
    AnimatedComponent
  }
}
</script>

<style scoped>
.animationWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h1 {
  color: black;
}
p {
  color: black;
  font-size: 1rem;
  padding: 1rem;
  max-width: 40rem;
}
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 100vw;
  height: 90vh;
}
.homeButton {
  height: 2.5rem;
  background: linear-gradient(114.7deg, #000000 10.42%, #2e2e2e 90.09%);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14rem;
}
</style>
