<template>
  <div class="aboutUsWrapper">
    <h1 style="color: white"><a id="aboutUsPage">About Us</a></h1>
    <div class="copy">
      <p class="aboutBody">
        <i>JS Trucking and Excavation LLC.</i> takes pride in providing honest
        and reliable services in Lewis, Thurston and Cowlitz counties.
      </p>
      <p class="aboutBody">
        Call, text or email any questions or inquiries you might have on your
        upcoming projects, residential or commercial, pre-existing or new
        construction. If we don’t provide the services you’re needing? We will
        do our best to lead you in the right direction.
      </p>
      <p class="aboutBody">
        Some of the services we provide are land clearing, road construction,
        grading, utility trenching and installation, ditching, and rock product
        delivery, just to name a few. With over 20 years experience in this
        industry. We understand what it means to put the customer first.
      </p>
    </div>
    <div class="callNowButton">
      <h4 style="color: black; margin: 0">
        <a href="tel:3602698462">call now</a>
      </h4>
    </div>
  </div>
</template>

<style scoped>
#aboutUsPage {
  padding-top: 2rem;
}
.aboutUsWrapper {
  min-height: 60vh;
  width: 100vw;
  background: linear-gradient(114.7deg, #000000 10.42%, #2e2e2e 90.09%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1rem;
}

.aboutBody {
  padding: 0 2rem;
  max-width: 50rem;
  display: block;
  font-size: 1.2rem;
}

.callNowButton {
  height: 2.5rem;
  width: 10rem;
  background: white;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 5rem;
}

@media (max-width: 500px) {
  .aboutBody {
    font-size: 0.9rem;
  }
}
</style>
