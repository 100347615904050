<template>
  <div class="contactWrapper">
    <h1 style="padding-top: 2rem"><a id="contactPage">Contact</a></h1>
    <form ref="form" @submit.prevent="sendEmail">
      <div class="rowWrapper">
        <div class="fieldWrapper seprateBox">
          <label>First Name</label>
          <input class="inputBox" type="text" name="user_firstName" required />
        </div>
        <div class="fieldWrapper seprateBox">
          <label>Last Name</label>
          <input class="inputBox" type="text" name="user_lastName" required />
        </div>
      </div>
      <div class="rowWrapper">
        <div class="fieldWrapper seprateBox">
          <label>Email</label>
          <input class="inputBox" type="email" name="user_email" required />
        </div>
        <div class="fieldWrapper seprateBox">
          <label>Phone #</label>
          <input class="inputBox" type="tel" name="user_number" required />
        </div>
      </div>
      <div class="fieldWrapper">
        <label>Project Description</label>
        <textarea
          class="inputBox rowWrapper"
          style="height: 20vh"
          name="message"
        ></textarea>
      </div>
      <input class="sendButton" type="submit" value="Send Message" required />
    </form>
  </div>
</template>

<script>
import emailjs from "@emailjs/browser";

export default {
  methods: {
    sendEmail() {
      emailjs
        .sendForm(
          "service_wdhicqb",
          "template_2qt5y68",
          this.$refs.form,
          "user_Ts3qlkk3uYGLbMh4BqLZC"
        )
        .then(
          (result) => {
            console.log("SUCCESS!", result.text);
            window.location.href = "/success";
          },
          (error) => {
            console.log("FAILED...", error.text);
          }
        );
    },
  },
};
</script>

<style scoped>
.contactWrapper {
  padding: 5rem 0rem 5rem 0rem;
}
form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
label {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: black;
  padding-bottom: 0.5rem;
}
.inputBox {
  background-color: #eeeeee;
  border: none;
  height: 2rem;
  width: 100%;
  font-family: Poppins;
  font-weight: 600;
}
.rowWrapper {
  display: flex;
  width: 80vw;
  max-width: 50rem;
  justify-content: space-between;
}
.fieldWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 1rem;
}
.seprateBox {
  width: 48%;
}
.sendButton {
  font-family: Helvetica;
  font-style: normal;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #ffffff;

  height: 2.5rem;
  background: linear-gradient(114.7deg, #000000 10.42%, #2e2e2e 90.09%);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14rem;
  max-width: 14rem;
  width: 80%;
  margin: 1rem;
}
@media (max-width: 750px) {
  .rowWrapper {
    display: flex;
    flex-direction: column;
  }
  .seprateBox {
    width: 100%;
  }
}
</style>
