<template>
  <div class="homeWrapper">
    <div class="photoWrapper">
      <img
        class="jsLogo"
        src="../assets/js_logo_web.png"
        alt="JS Trucking Logo"
      />
      <img
        class="coverPhoto"
        src="../assets/JS3.jpg"
        alt="Dump Truck in Snow"
      />
    </div>
    <Services />
    <About />
    <Projects />
    <Contact />
  </div>
</template>

<script>
import Services from "../components/Services.vue";
import About from "../components/About.vue";
import Projects from "../components/Projects.vue";
import Contact from "../components/Contact.vue";

export default {
  name: "Home",
  components: {
    Services,
    About,
    Projects,
    Contact,
  },
};
</script>

<style scoped>
.homeWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.jsLogo {
  width: 60%;
  max-width: 30rem;
  min-width: 12rem;
  position: absolute;
  z-index: 3;
}
.photoWrapper {
  max-height: 70vh;
  min-height: 50vh;
  width: 100vw;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
}

.coverPhoto {
  object-fit: cover;
  max-height: 70vh;
  min-height: 50vh;
  width: 100vw;
  opacity: 0.5;
}

@media (max-width: 500px) {
  .photoWrapper {
    height: 20rem;
  }
  .coverPhoto {
    height: 20rem;
  }
}
</style>
