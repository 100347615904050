<template>
  <div class="navWrapper">
    <a href="/">
      <img
        class="jsIcon"
        src="../assets/js_logo_icon_web.png"
        alt="JS Logo White"
      />
    </a>
    <div class="nav">
      <h4 class="navOption hideInMobile">
        <a href="/#servicePage">services</a>
      </h4>
      <h4 class="navOption hideInMobile"><a href="/#aboutUsPage">about</a></h4>
      <h4 class="navOption hideInMobile">
        <a href="/#contactPage">contact</a>
      </h4>
      <div class="callNowButton navOption">
        <h4 style="color: black; margin: 0">
          <a href="tel:3602698462">call now</a>
        </h4>
      </div>
    </div>
  </div>
</template>

<style scoped>
.navWrapper {
  width: 100vw;
  height: 6rem;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(114.7deg, #000000 10.42%, #2e2e2e 90.09%);
  z-index: 50;
}

.nav {
  display: flex;
  margin: 1rem 0rem 1rem 0rem;
  align-items: center;
}

.navOption {
  padding: 0rem 1rem 0rem 1rem;
}

.callNowButton {
  height: 2.5rem;
  margin-right: 3vw;
  margin-left: 1rem;
  background: white;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.jsIcon {
  margin-left: 3vw;
  height: 5rem;
}

@media (max-width: 750px) {
  .hideInMobile {
    display: none;
  }
  .jsIcon {
    height: 4rem;
  }
}
</style>
