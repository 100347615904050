<template>
  <div class="footer">
    <img
      class="logo"
      src="../assets/js_logo_web_long.png"
      alt="JS Trucking Logo"
    />
    <div class="buttonWrapper">
      <a
        href="https://www.facebook.com/JS-Trucking-Excavation-LLC-102665825569047"
        ><img class="socialIcon" src="../assets/facebook.png" alt="Facebook"
      /></a>
      <a href="https://goo.gl/maps/jzrG3T6Y8763a8ut8"
        ><img class="socialIcon" src="../assets/google.png" alt="Google"
      /></a>
      <div class="callNowButton">
        <h4 class="buttonType" style="color: black; margin: 0">
          <a href="tel:3602698462">call now</a>
        </h4>
      </div>
    </div>
  </div>
</template>

<style scoped>
.footer {
  width: 100%;
  height: 8rem;
  background: linear-gradient(114.7deg, #000000 10.42%, #2e2e2e 90.09%);
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo {
  height: 6rem;
  margin-left: 1vw;
}
.socialIcon {
  width: 2rem;
  height: 2rem;
  margin: 1rem;
}
.callNowButton {
  height: 2.5rem;
  margin: 1rem;
  margin-right: 3vw;
  background: white;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 0rem 1rem 0rem 1rem;
}

.buttonWrapper {
  display: flex;
  align-items: center;
}

@media (max-width: 600px) {
  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 14rem;
  }
}
</style>
